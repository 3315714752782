import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOAD_URL, QUESTION, ADMIN, CONTENT_TYPE, CATEGORIES, QUIZ, COURSE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { ImageUrl } from "../../helpers/Functions";
const { Option } = Select;
const { Dragger } = Upload;

const correctOptions = new Array(4).fill("Option ").map((val, ind) => val + (ind + 1))
const iconFontSize = 20

function QuizAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [course, setCourse] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);
  const [questionArray, setQuestionArray] = useState(["MCQ"])
  const [quiz, setQuiz] = useState(null)
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    getQuizById();
  }, []);

  const getQuizById = async () => {
    setLoading(true);
    const res = await Get(
      `${QUIZ.getQuizById}${id}`,
      token, {
      limit: "100"
    }
    );

    setQuiz(res.data.quiz);
    setLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    setLoading(true);
    const res = await Get(
      `${COURSE.getAllCourses}`,
      token, {
      limit: "100"
    }
    );

    setCourse(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        QUESTION.toggleStatus + "/" + question._id,
        token,
        {}
      );
      const newUser = { ...question };

      newUser.isActive = !question.isActive;
      setModalOpen(false);
      setQuestion(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let obj = { ...values, quizDate: dayjs(values.quizDate), questions: [] }
    Post(QUIZ.updateQuiz + id, obj, token, null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Quiz Update Successfully", "success");
          navigate(-1)


          setEditMode(false);
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };


  const uploadOptions = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = ['application/pdf', 'application/msword', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    }
  };

  const addQuestion = (e) => {
    try {
      let { innerText } = e.target
      let [, text] = innerText?.split(' ')
      setQuestionArray([...questionArray, text])
    } catch (error) {
      console.log(error)
    }
  }

  const removeQuestion = (ind) => {
    try {
      let arr = [...questionArray]
      arr.splice(ind, 1)
      setQuestionArray(arr)
    } catch (error) {
      console.log(error)
    }
  }

  // const [elRefs, setElRefs] = useState([])

  // setElRefs((val) =>
  // questionArray
  //     .map((_, i) => val[i] || createRef()),
  // );

  // console.log(elRefs[0]?.current)

  // const myRefs = useRef([]);

  // myRefs.current = questionArray.map((element, i) => myRefs.current[i] ?? createRef());

  // const myRefs = questionArray.map(() => useRef(null))

  // console.log("aaa", myRefs[0])

  // const btnRef = useRef(null)
  // console.log('a', btnRef)

  // const [enabledForm, setEnabledForm] = useState(questionArray?.length - 1)


  const enabledForm = useMemo(() => {
    return questionArray?.length - 1
  }, [questionArray])

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              {editMode ? "Edit Quiz Details" : "View Quiz Details"}
            </h1>
          </Col>

        </Row>
        <br />

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        )
        }

        {!loading && quiz && (<>


          <Form
            layout="vertical"
            name="basic"
            className="contactForm"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row style={{ padding: "20px" }}>
              <Col xs={24} md={18}>

                {editMode ? (
                  <>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name="title"
                          initialValue={quiz?.title}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input quiz title",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Title"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Description:
                        </Typography.Title>

                        <Form.Item
                          name="description"
                          initialValue={quiz?.description}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Quiz description",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Description"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Course :
                        </Typography.Title>

                        <Form.Item
                          name="course"
                          initialValue={quiz?.course?._id}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input quiz course",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Course"
                            className="signupSelectBox"
                          >
                            {course.map((item, index) => {
                              return (<Option value={item._id}>{item?.title}</Option>)
                            })}

                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Passing Percent:
                        </Typography.Title>

                        <Form.Item
                          name="passingPercentage"
                          initialValue={quiz?.passingPercentage}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Course passingPercentage",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Passing Percent"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>

                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Quiz Date :
                        </Typography.Title>

                        <Form.Item
                          name="quizDate"
                          initialValue={dayjs(quiz?.quizDate)}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input start Date",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} size="large"
                            placeholder="Enter start Date"
                            className="signupFormInput" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Title
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {quiz?.title}
                        </h5>
                      </Col>

                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Description{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {quiz?.description}
                        </h5>
                      </Col>

                    </Row>

                    <br />
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Course
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {quiz?.course?.title}
                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Passing Percent
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {quiz?.passingPercentage}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Due Date
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {dayjs(quiz?.quizDate).format("M/D/YYYY")}

                        </h5>
                      </Col>

                    </Row>
                    <br />
                  </>
                )}

                <Row style={{ marginTop: 30 }}>
                  {editMode && (
                    <>
                      {" "}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                      >
                        Save Changes
                      </Button>
                      &emsp;
                      <Button
                        className="fontFamily1"
                        style={{
                          marginTop: "0px",
                          padding: "10px 30px",
                          cursor: "pointer",
                          color: "black",
                          borderRadius: "5px",
                          height: "auto",
                          border: "1px solid #203657",
                          fontWeight: "bold",
                        }}
                        ghost
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {!editMode && (
                    <Row justify={"center"}>
                      <Col style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          htmlType="button"
                          className="loginButton"
                          onClick={() => setEditMode(true)}
                        >
                          Edit Quiz
                        </Button>
                      </Col>
                    </Row>
                  )}

                </Row>

              </Col>
            </Row>
          </Form>
          <Row style={{ padding: "20px", backgroundColor: "" }}>
            <Col xs={24} md={18}>
              <Row justify={"end"}>
                <Col style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="loginButton"
                    onClick={() => navigate(`/quiz/${id}/add-question`)}
                  >
                    Add Question
                  </Button>
                </Col>
              </Row>
              <Row>
                {quiz?.questions?.map(val => <Question question={val} />)}
              </Row>
            </Col>
          </Row>
        </>)}
        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {quiz?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To  {quiz?.isActive ? "Deactivate" : "Activate"} This User?
        </Typography.Text>
      </Modal>


      <br />
      <br />
    </Layout>
  );
}
export default QuizAdd;

const Question = ({ question }) => {
  const token = useSelector((state) => state.user.userToken);
  // const question = {
  //   title: "Question 1",
  //   options: ["Option A", "Option B", "Option C", "Option D"],
  //   correctOption: "Option A",
  //   score: 10,
  //   quiz: "60a1a2b27b1c1322e8f12345"
  // }
  function deleteQuestion(id) {
    try {
      debugger
      // setLoading(true);
      Post(QUESTION.deleteQuestion + id, {}, token, null)
        .then((response) => {
          // setLoading(false);
          if (response?.data?.status) {
            swal("Success!", "Question Deleted Successfully", "success");
          } else {
            swal("Oops!", response.data.message, "error");
          }
        })
        .catch((e) => {
          // setLoading(false);
        });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>

      <Row style={{ backgroundColor: "#00A79D", padding: "10px 20px", marginTop: 20, width: '100%', color: '#fff' }}>
        <p>{question?.title}</p>
      </Row>
      <Row justify={"end"} style={{marginTop: 20, width: '100%'}}>
        <Col xs={24}>
          <div style={{ width: '100%' }}>
            {question?.options?.map((val, ind) => (
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#3d4142",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                {`Option ${ind + 1}: ${val}`}
              </Typography.Title>
            ))}
          </div>
          <div>
            <Typography.Title
              className="fontFamily1"
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#3d4142",
                textAlign: "left",
                marginBottom: 20,
              }}
            >
              {`Correct Option: ${question?.correctOption}`}
            </Typography.Title>
          </div>
          <div>
            <Typography.Title
              className="fontFamily1"
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#3d4142",
                textAlign: "left",
                marginBottom: 20,
              }}
            >
              {`Score: ${question?.score}`}
            </Typography.Title>
          </div>
        </Col>
        <Col style={{ display: 'flex', gap: 10 }}>
          {/* <Button>
            <EditOutlined style={{ cursor: "pointer", fontSize: iconFontSize }} />
          </Button> */}
          <Button>
            <DeleteOutlined style={{ cursor: "pointer", fontSize: iconFontSize }} onClick={()=>{deleteQuestion(question?._id)}} />
          </Button>
        </Col>
      </Row>
    </>
  )
}

const QuestionForm = (props) => {

  const disabled = props.enabledForm !== props.ind

  const onFinish = (e) => {
    console.log(e)
  }
  return (
    <Form name={`form${props.ind + 1}`}
    // onFinish={onFinish}
    //  onFinishFailed={onFinishFailed}

    >

      <Row style={{ padding: "20px", backgroundColor: "" }}>
        <Col xs={24} md={18}>
          <Row justify={"end"}>
            <Col style={{ display: 'flex', gap: 10 }}>
              {disabled ? <Button>

                <EditOutlined style={{ cursor: "pointer", fontSize: iconFontSize }} />
              </Button>
                :
                <>
                  <Button htmlType="submit">

                    <SaveOutlined style={{ cursor: "pointer", fontSize: iconFontSize }} />
                  </Button>
                </>
              }
              <Button>

                <CloseCircleOutlined style={{ cursor: "pointer", fontSize: iconFontSize }} onClick={() => { props.removeQuestion(props.ind) }} />
              </Button>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col
              xs={24}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Question :
              </Typography.Title>

              <Form.Item
                name={`title${props.ind}`}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Question",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  size="large"
                  placeholder="Enter Question"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 1:
              </Typography.Title>

              <Form.Item
                name="option1"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 2:
              </Typography.Title>

              <Form.Item
                name="option2"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 3:
              </Typography.Title>

              <Form.Item
                name="option3"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 4:
              </Typography.Title>

              <Form.Item
                name="option4"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Correct Option :
              </Typography.Title>

              <Form.Item
                name="category"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select question category",
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  placeholder="Select Option"
                  className="signupSelectBox"
                >
                  {correctOptions.map((item, index) => {
                    return (<Option value={item}>{item}</Option>)
                  })}

                </Select>
              </Form.Item>
            </Col>
          </Row>


        </Col>
      </Row>
    </Form>
  )
}

const MCQForm = (props) => {
  return (
    <>
      <Row style={{ padding: "20px", backgroundColor: "" }}>
        <Col xs={24} md={18}>
          <Row justify={"end"}>
            <Col style={{ display: 'flex', gap: 10 }}>
              <EditOutlined style={{ cursor: "pointer" }} onClick={() => { props.removeQuestion(props.ind) }} />
              <CloseCircleOutlined style={{ cursor: "pointer" }} onClick={() => { props.removeQuestion(props.ind) }} />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col
              xs={24}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Question :
              </Typography.Title>

              <Form.Item
                name="title"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Question",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Question"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 1:
              </Typography.Title>

              <Form.Item
                name="option1"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
                initialValue={"Yes"}
              >
                <Input
                  size="large"
                  placeholder="Enter Option"
                  disabled
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 2:
              </Typography.Title>

              <Form.Item
                name="option2"
                initialValue={"No"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Correct Option :
              </Typography.Title>

              <Form.Item
                name="category"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select question category",
                  },
                ]}
              >
                <Select
                  placeholder="Select Option"
                  className="signupSelectBox"
                >
                  {correctOptions.slice(0, 2).map((item, index) => {
                    return (<Option value={item}>{item}</Option>)
                  })}

                </Select>
              </Form.Item>
            </Col>
          </Row>


        </Col>
      </Row>
    </>
  )
}
