import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Typography
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { CONTENT_TYPE, INVOICE } from "../../config/constants";
const { Option } = Select;

const statusData = ["Paid", "Cancel", "Refund", "Unpaid"]

function InvoiceAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState({status: "Unpaid"});

  const onFinish = (values) => {
    let data = {...values, amount: +values?.amount}
    setLoading(true);
    Post(INVOICE.addInvoice, data, token, null, CONTENT_TYPE.JSON)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Invoice Added Successfully", "success");
          navigate(-1)


        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };


  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Add Invoice
            </h1>
          </Col>

        </Row>
        <br />

        <Form
          layout="vertical"
          name="basic"
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={18}>
              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Invoice ID :
                  </Typography.Title>

                  <Form.Item
                    name="invoiceId"
                    initialValue={invoice?.invoiceId}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Invoice ID",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Invoice ID"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Customer Name :
                  </Typography.Title>

                  <Form.Item
                    name="customerName"
                    initialValue={invoice?.customerName}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Customer Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Customer Name"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Email Address :
                  </Typography.Title>

                  <Form.Item
                    name="email"
                    initialValue={invoice?.email}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Date :
                      </Typography.Title>
                   
                      <Form.Item
                        name="date"
                        initialValue={dayjs(invoice?.date)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select  Date",
                          },
                        ]}
                      >
                        <DatePicker  style={{width:"100%"}} size="large"
                          placeholder="Enter Date"
                          className="signupFormInput"/>
                      </Form.Item>
                    </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Billed :
                  </Typography.Title>

                  <Form.Item
                    name="amount"
                    initialValue={invoice?.amount}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Billed Amount",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Billed Amount"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Status :
                  </Typography.Title>

                  <Form.Item
                    name="status"
                    initialValue={invoice?.status}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Status"
                      className="signupSelectBox"
                      disabled
                    >
                      {statusData?.map(val => <Option key={val} value={val}>{val}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <>
                  {" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Add Invoice"}
                  </Button>
                  &emsp;
                  <Button
                    className="fontFamily1"
                    style={{
                      marginTop: "0px",
                      padding: "10px 30px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "5px",
                      height: "auto",
                      border: "1px solid #203657",
                      fontWeight: "bold",
                    }}
                    ghost
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1)
                    }}
                  >
                    Cancel
                  </Button>
                </>
              </Row>
            </Col>
          </Row>
        </Form>


        <br />
        <br />
      </div>

      <br />
      <br />
    </Layout>
  );
}
export default InvoiceAdd;
