import axios from "axios";
import { BASE_URL, CONTENT_TYPE } from "../constants";

async function Put(path, putData, token, paramObj, contentType = CONTENT_TYPE.JSON) {
  try {
    const url = `${BASE_URL}${path}`;
    const headers = {
      "Content-Type": contentType,
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    const config = {
      headers,
      ...(paramObj && { params: paramObj }),
    };

    const response = await axios.put(url, putData, config);
    return response;
  } catch (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/signin?error_code=session_expired";
      console.log("SESSION EXPIRED");
    }
    throw error;
  }
}

export { Put };
