import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message,
  Radio
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOAD_URL, LEAD, ADMIN, CONTENT_TYPE, CATEGORIES } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { ImageUrl } from "../../helpers/Functions";
const { Option } = Select;
const { Dragger } = Upload;


const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const currencyData = ["$"]
const typeData = ["Person", "Organization"]
const sourceData = ["Phone Calls"]
const industryData = ["Financial Services"]
const visibilityData = ["Public", "Private", "Select People"]
const statusData = ["Active", "InActive"]

function LeadAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lead, setLead] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    const res = await Get(
      `${CATEGORIES.getAllcategories}`,
      token, {
      limit: "100"
    }
    );

    setCategories(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        LEAD.toggleStatus + "/" + lead._id,
        token,
        {}
      );
      const newUser = { ...lead };

      newUser.isActive = !lead.isActive;
      setModalOpen(false);
      setLead(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    let data = {...values, value: +values?.value}
    setLoading(true);
    Post(LEAD.addLead, data, token, null, CONTENT_TYPE.JSON)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Lead Added Successfully", "success");
          navigate(-1)


          setEditMode(false);
          setImageNew();
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };


  const uploadOptions = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = ['application/pdf', 'application/msword', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    }
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Add Lead
            </h1>
          </Col>

        </Row>
        <br />

        <Form
          layout="vertical"
          name="basic"
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={12}>
              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Lead Name :
                  </Typography.Title>

                  <Form.Item
                    name="name"
                    initialValue={lead?.name}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Lead Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Lead Name"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Lead Type :
                  </Typography.Title>

                  <Form.Item
                    name="type"
                    initialValue={lead?.type}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select lead type",
                      },
                    ]}
                  >
                    <Radio.Group >
                      {typeData?.map(val => <Radio key={val} value={val}>{val}</Radio>)}

                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Company :
                  </Typography.Title>

                  <Form.Item
                    name="company"
                    initialValue={lead?.company}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Company Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Company"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Value :
                  </Typography.Title>

                  <Form.Item
                    name="value"
                    initialValue={lead?.value}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Lead Value",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Value"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Currency :
                  </Typography.Title>

                  <Form.Item
                    name="currency"
                    initialValue={lead?.currency}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select currency",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Currency"
                      className="signupSelectBox"
                    >
                      {currencyData?.map(val => <Option key={val} value={val}>{val}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Phone Number :
                  </Typography.Title>

                  <Form.Item
                    name="phone"
                    initialValue={lead?.phone}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Phone",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Phone"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Email :
                  </Typography.Title>

                  <Form.Item
                    name="email"
                    initialValue={lead?.email}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Source :
                  </Typography.Title>

                  <Form.Item
                    name="source"
                    initialValue={lead?.source}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Source",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Source"
                      className="signupSelectBox"
                    >
                      {sourceData?.map(val => <Option key={val} value={val}>{val}</Option>)}


                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Industry :
                  </Typography.Title>

                  <Form.Item
                    name="industry"
                    initialValue={lead?.industry}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select industry",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Industry"
                      className="signupSelectBox"
                    >
                      {industryData?.map(val => <Option key={val} value={val}>{val}</Option>)}


                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Owner :
                  </Typography.Title>

                  <Form.Item
                    name="owner"
                    initialValue={lead?.owner}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Owner",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Owner"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Description :
                  </Typography.Title>

                  <Form.Item
                    name="description"
                    initialValue={lead?.description}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input description",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="signupTextField"
                      rows={4}
                      placeholder="Enter description"
                    // value={pushNotifcation?.description}
                    // onChange={(e) => handleChange("description", e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Visibility :
                  </Typography.Title>

                  <Form.Item
                    name="visibility"
                    initialValue={lead?.visibility}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select visibility",
                      },
                    ]}
                  >
                    <Radio.Group >
                      {visibilityData?.map(val => <Radio key={val} value={val}>{val}</Radio>)}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Status :
                  </Typography.Title>

                  <Form.Item
                    name="status"
                    initialValue={lead?.status}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select status",
                      },
                    ]}
                  >
                    <Radio.Group >
                      {statusData?.map(val => <Radio key={val} value={val}>{val}</Radio>)}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <>
                  {" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Add Lead"}
                  </Button>
                  &emsp;
                  <Button
                    className="fontFamily1"
                    style={{
                      marginTop: "0px",
                      padding: "10px 30px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "5px",
                      height: "auto",
                      border: "1px solid #203657",
                      fontWeight: "bold",
                    }}
                    ghost
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              </Row>
            </Col>
          </Row>
        </Form>


        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {lead?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To  {lead?.isActive ? "Deactivate" : "Activate"} This User?
        </Typography.Text>
      </Modal>

      <br />
      <br />
    </Layout>
  );
}
export default LeadAdd;
