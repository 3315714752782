import React from 'react';
// import { useHistory } from 'react-router-dom';
import './SuccessPayment.css';

const SuccessPayment = () => {
    // const history = useHistory();

    const handleGoBackHome = () => {
        // history.push('/');
    };

    return (
        <div className="success-payment">
            <div className="success-payment-container">
                <h1>Payment Successful!</h1>
                <p>Thank you for your payment. Your transaction was successful.</p>
                {/* <p>Order ID: <strong></strong></p>
                <p>Transaction ID: <strong></strong></p> */}
                {/* <button className="btn-home" onClick={handleGoBackHome}>Go to Homepage</button> */}
            </div>
        </div>
    );
};

export default SuccessPayment;
