import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message,
  Radio
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOAD_URL, INVOICE, ADMIN, CONTENT_TYPE, CATEGORIES } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { ImageUrl } from "../../helpers/Functions";
import { Put } from "../../config/api/put";
const { Option } = Select;
const { Dragger } = Upload;


const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const currencyData = ["$"]
const typeData = ["Person", "Organization"]
const sourceData = ["Phone Calls"]
const industryData = ["Financial Services"]
const visibilityData = ["Public", "Private", "Select People"]
const statusData = ["Paid", "Cancel", "Refund", "Unpaid"]

function InvoiceEdit() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  console.log("🚀 ~ InvoiceEdit ~ invoice:", invoice)
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = async () => {
    setLoading(true);
    const res = await Get(
      `${INVOICE.getInvoice}?id=${id}`,
      token
    );

    console.log("<<<<", invoice)

    setInvoice(res?.data?.docs[0]);
    setLoading(false);
    // setFileList(createFileList(res.data.course?.files))
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        INVOICE.toggleStatus + "/" + invoice._id,
        token,
        {}
      );
      const newUser = { ...invoice };

      newUser.isActive = !invoice.isActive;
      setModalOpen(false);
      setInvoice(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    let data = { ...values, amount: +values?.amount }
    setLoading(true);
    Put(INVOICE.updateInvoice + id , data, token, null, CONTENT_TYPE.JSON)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Invoice Updated Successfully", "success");
          navigate(-1)


          setEditMode(false);
          setImageNew();
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };


  const uploadOptions = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = ['application/pdf', 'application/msword', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    console.log('a')
  }, [invoice])

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Update Invoice
            </h1>
          </Col>

        </Row>
        <br />

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        )
        }
        {!loading && <Form
          layout="vertical"
          name="basic"
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ ...invoice, date: dayjs(invoice?.date) }}
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={18}>
              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Invoice ID :
                  </Typography.Title>

                  <Form.Item
                    name="invoiceId"
                    style={{ width: "100%" }}
                    // initialValue={invoice?.invoiceId}
                    rules={[
                      {
                        required: true,
                        message: "Please input Invoice ID",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Invoice ID"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Customer Name :
                  </Typography.Title>

                  <Form.Item
                    name="customerName"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Customer Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Customer Name"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Email Address :
                  </Typography.Title>

                  <Form.Item
                    name="email"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Date :
                  </Typography.Title>

                  <Form.Item
                    name="date"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select  Date",
                      },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} size="large"
                      placeholder="Enter Date"
                      className="signupFormInput" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Billed :
                  </Typography.Title>

                  <Form.Item
                    name="amount"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Billed Amount",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Billed Amount"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Status :
                  </Typography.Title>

                  <Form.Item
                    name="status"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Status"
                      className="signupSelectBox"
                    >
                      {statusData?.map(val => <Option key={val} value={val}>{val}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <>
                  {" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Update Invoice"}
                  </Button>
                  &emsp;
                  <Button
                    className="fontFamily1"
                    style={{
                      marginTop: "0px",
                      padding: "10px 30px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "5px",
                      height: "auto",
                      border: "1px solid #203657",
                      fontWeight: "bold",
                    }}
                    ghost
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1)

                    }}
                  >
                    Cancel
                  </Button>
                </>
              </Row>
            </Col>
          </Row>
        </Form>}


        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {invoice?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To  {invoice?.isActive ? "Deactivate" : "Activate"} This User?
        </Typography.Text>
      </Modal>

      <br />
      <br />
    </Layout>
  );
}

export default InvoiceEdit;