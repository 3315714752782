import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message
} from "antd";
import dayjs from "dayjs";
import { InboxOutlined, UploadOutlined, InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOAD_URL, LEAD, ADMIN, CONTENT_TYPE, CATEGORIES } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { ImageUrl } from "../../helpers/Functions";
const { Option } = Select;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function LeadDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [lead, setLead] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    getLead();
    getCategories();
  }, []);

  const getLead = async () => {
    setLoading(true);
    const res = await Get(
      `${LEAD.getLead}?id=${id}`,
      token
    );

    console.log("<<<<", lead)

    setLead(res?.data?.docs[0]);
    setLoading(false);
    // setFileList(createFileList(res.data.course?.files))
  };

  const getCategories = async () => {
    setLoading(true);
    const res = await Get(
      `${CATEGORIES.getAllcategories}`,
      token, {
      limit: "100"
    }
    );

    console.log("<<<<>>>>>", res)

    setCategories(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        LEAD.toggleStatus + "/" + lead._id,
        token,
        {}
      );
      const newUser = { ...lead };

      newUser.isActive = !lead.isActive;
      setModalOpen(false);
      setLead(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {

    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    formObject.append("features", values.features.toString().split(","));

    for (const key in values) {
      if (key !== "image" && key !== "features") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    const prevFiles = fileList?.filter(x => !Boolean(x.size))?.map(x => x.name)
    const newFiles = fileList?.filter(x => Boolean(x.size))
    formObject.append('previousfiles', JSON.stringify(prevFiles));
    newFiles?.forEach(file => {
      formObject.append('files', file.originFileObj);
    });

    Post(LEAD.updateLead + id, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Lead Updated Successfully", "success");
          getLead()

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const uploadOptions = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 10,
    multiple: true,
    defaultFileList: createFileList(lead?.files),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = ['application/pdf', 'application/msword', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    },
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              {editMode ? "Edit Lead Details" : "View Lead Details"}
            </h1>
          </Col>

        </Row>
        <br />

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        )
        }

        {!loading && lead && (<>


          <Form
            layout="vertical"
            name="basic"
            className="contactForm"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row style={{ padding: "20px" }}>
              <Col xs={24} md={18}>

                <Row gutter={20}>
                  <Col xs={12} sm={12} >
                    {editMode ? <Upload {...uploadOptions}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload> :
                      lead?.files ? <div>
                        <h1 className="mb-2">Documents</h1>
                        <RecordViewer pdfLinks={lead?.files} />
                      </div> : <></>}
                  </Col>
                </Row>


                {editMode ? (
                  <>
                    {/* <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Lead Code :
                      </Typography.Title>
                   
                      <Form.Item
                        name="courseCode"
                        initialValue={lead?.courseCode}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Lead Code",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter LeadCode"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Title :
                      </Typography.Title>
                   
                      <Form.Item
                        name="title"
                        initialValue={lead?.title}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Title"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Description:
                      </Typography.Title>
                   
                      <Form.Item
                        name="description"
                        initialValue={lead?.description}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Lead description",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Description"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Category :
                      </Typography.Title>
                   
                      <Form.Item
                        name="category"
                        initialValue={lead?.category._id}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course category",
                          },
                        ]}
                      >
                         <Select
           placeholder="Select Category"
           className="signupSelectBox"
        >
          {categories.map((item,index) => {
            return(<Option value={item._id}>{item.title}</Option>)
          })}

        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Start Date :
                      </Typography.Title>
                   
                      <Form.Item
                        name="startDate"
                        initialValue={dayjs(lead?.startDate)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input start Date",
                          },
                        ]}
                      >
                        <DatePicker  style={{width:"100%"}} size="large"
                          placeholder="Enter start Date"
                          className="signupFormInput"/>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Duration :
                      </Typography.Title>
                   
                      <Form.Item
                        name="duration"
                        initialValue={lead?.duration}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course duration",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Duration"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                  <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Price :
                      </Typography.Title>
                   
                      <Form.Item
                        name="price"
                        initialValue={lead?.price}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input price",
                          },
                        ]}
                      >
                        <Input
                        prefix="$"
                          size="large"
                          placeholder="Enter Price"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Features :
                      </Typography.Title>
                   
                      <Form.Item
                        name="features"
                        initialValue={lead?.features}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Features",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Features"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                 
                  </Row> */}
                  </>
                ) : (
                  <>
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Lead Name{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.name}
                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Company Name{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.company}
                        </h5>
                      </Col>

                    </Row>

                    <br />
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Email Address{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.email}
                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Phone
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.phone}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Lead Owner{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.owner}

                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Status{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {lead?.status}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Created Date{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {dayjs(lead?.createdAt).format("M/D/YYYY")}

                        </h5>
                      </Col>

                    </Row>
                    <br />
                  </>
                )}

                <Row style={{ marginTop: 30 }}>
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      disabled={loading}
                      onClick={() => { navigate(`/leads/edit/${id}`) }}
                    >
                      {loading ? "Loading..." : "Edit Lead"}
                    </Button>
                    &emsp;
                  </>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  {editMode && (
                    <>
                      {" "}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                      >
                        Save Changes
                      </Button>
                      &emsp;
                      <Button
                        className="fontFamily1"
                        style={{
                          marginTop: "0px",
                          padding: "10px 30px",
                          cursor: "pointer",
                          color: "black",
                          borderRadius: "5px",
                          height: "auto",
                          border: "1px solid #203657",
                          fontWeight: "bold",
                        }}
                        ghost
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {/* {!editMode && (
                  <Row justify={"center"}>
                    <Col style={{ textAlign: "center" }}>
                      <Button
                        type="primary"
                        htmlType="button"
                        className="loginButton"
                        onClick={() => setEditMode(true)}
                      >
                        Edit Lead
                      </Button>
                    </Col>
                  </Row>
                )} */}
                </Row>
              </Col>
            </Row>
          </Form>


        </>)}



        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {lead?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To  {lead?.isActive ? "Deactivate" : "Activate"} This User?
        </Typography.Text>
      </Modal>


      <br />
      <br />
    </Layout>
  );
}
export default LeadDetails;

const RecordViewer = ({ pdfLinks }) => {
  return (
    <List
      bordered
      dataSource={pdfLinks}
      renderItem={item => (
        <List.Item>
          <a href={UPLOAD_URL + item} target="_blank" rel="noopener noreferrer">
            {item}
          </a>
        </List.Item>
      )}
    />
  );
};

const createFileList = (arr) => {
  try {
    return arr?.map(item => ({
      uid: item,
      name: item,
      status: 'done',
      url: UPLOAD_URL + item,
      percent: 100,
    }))
  } catch (error) {
    console.log(error)
  }
}