import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Layout,
  List,
  Row,
  Skeleton,
  Upload,
  message
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import { Post } from "../../config/api/post";
import { CONTENT_TYPE, INVOICE, UPLOAD_URL } from "../../config/constants";

function InvoiceDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = async () => {
    setLoading(true);
    const res = await Get(
      `${INVOICE.getInvoice}?id=${id}`,
      token
    );

    setInvoice(res?.data?.docs[0]);
    setLoading(false);
  };

  const onFinish = (values) => {

    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    formObject.append("features", values.features.toString().split(","));

    for (const key in values) {
      if (key !== "image" && key !== "features") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    const prevFiles = fileList?.filter(x => !Boolean(x.size))?.map(x => x.name)
    const newFiles = fileList?.filter(x => Boolean(x.size))
    formObject.append('previousfiles', JSON.stringify(prevFiles));
    newFiles?.forEach(file => {
      formObject.append('files', file.originFileObj);
    });

    Post(INVOICE.updateInvoice + id, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Invoice Updated Successfully", "success");
          getInvoice()

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const uploadOptions = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 10,
    multiple: true,
    defaultFileList: createFileList(invoice?.files),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileList(info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = ['application/pdf', 'application/msword', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    },
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              {editMode ? "Edit Invoice Details" : "View Invoice Details"}
            </h1>
          </Col>

        </Row>
        <br />

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        )
        }

        {!loading && invoice && (<>


          <Form
            layout="vertical"
            name="basic"
            className="contactForm"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row style={{ padding: "20px" }}>
              <Col xs={24} md={18}>

                <Row gutter={20}>
                  <Col xs={12} sm={12} >
                    {editMode ? <Upload {...uploadOptions}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload> :
                      invoice?.files ? <div>
                        <h1 className="mb-2">Documents</h1>
                        <RecordViewer pdfLinks={invoice?.files} />
                      </div> : <></>}
                  </Col>
                </Row>


                {editMode ? (
                  <>
                    {/* <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Invoice Code :
                      </Typography.Title>
                   
                      <Form.Item
                        name="courseCode"
                        initialValue={invoice?.courseCode}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Invoice Code",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter InvoiceCode"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Title :
                      </Typography.Title>
                   
                      <Form.Item
                        name="title"
                        initialValue={invoice?.title}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Title"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Description:
                      </Typography.Title>
                   
                      <Form.Item
                        name="description"
                        initialValue={invoice?.description}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Invoice description",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Description"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Category :
                      </Typography.Title>
                   
                      <Form.Item
                        name="category"
                        initialValue={invoice?.category._id}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course category",
                          },
                        ]}
                      >
                         <Select
           placeholder="Select Category"
           className="signupSelectBox"
        >
          {categories.map((item,index) => {
            return(<Option value={item._id}>{item.title}</Option>)
          })}

        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                       Start Date :
                      </Typography.Title>
                   
                      <Form.Item
                        name="startDate"
                        initialValue={dayjs(invoice?.startDate)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input start Date",
                          },
                        ]}
                      >
                        <DatePicker  style={{width:"100%"}} size="large"
                          placeholder="Enter start Date"
                          className="signupFormInput"/>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Duration :
                      </Typography.Title>
                   
                      <Form.Item
                        name="duration"
                        initialValue={invoice?.duration}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course duration",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Duration"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                  <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Price :
                      </Typography.Title>
                   
                      <Form.Item
                        name="price"
                        initialValue={invoice?.price}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input price",
                          },
                        ]}
                      >
                        <Input
                        prefix="$"
                          size="large"
                          placeholder="Enter Price"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Features :
                      </Typography.Title>
                   
                      <Form.Item
                        name="features"
                        initialValue={invoice?.features}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Features",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Features"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                 
                  </Row> */}
                  </>
                ) : (
                  <>
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Invoice Id{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {invoice?.invoiceId}
                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Customer Name{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {invoice?.customerName}
                        </h5>
                      </Col>

                    </Row>

                    <br />
                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Email Address{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {invoice?.email}
                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Date
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {dayjs(invoice?.date).format("M/D/YYYY")}
                        </h5>
                      </Col>
                    </Row>
                    <br />

                    <Row style={{ padding: "10px" }}>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Billed{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {dayjs(invoice?.amount).format("M/D/YYYY")}

                        </h5>
                      </Col>
                      <Col xs={24} md={12}>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10
                          }}
                        >
                          Status{" "}
                        </h5>
                        <h5
                          style={{
                            display: "block",
                            fontSize: 16,
                            color: "#7a7e7f",
                            fontWeight: "normal",
                          }}
                        >
                          {invoice?.status}
                        </h5>
                      </Col>

                    </Row>
                    <br />
                  </>
                )}
                <Row style={{ marginTop: 30 }}>
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      disabled={loading}
                      onClick={() => { navigate(`/invoices/edit/${id}`) }}
                    >
                      {loading ? "Loading..." : "Edit Invoice"}
                    </Button>
                    &emsp;
                  </>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  {editMode && (
                    <>
                      {" "}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                      >
                        Save Changes
                      </Button>
                      &emsp;
                      <Button
                        className="fontFamily1"
                        style={{
                          marginTop: "0px",
                          padding: "10px 30px",
                          cursor: "pointer",
                          color: "black",
                          borderRadius: "5px",
                          height: "auto",
                          border: "1px solid #203657",
                          fontWeight: "bold",
                        }}
                        ghost
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {/* {!editMode && (
                  <Row justify={"center"}>
                    <Col style={{ textAlign: "center" }}>
                      <Button
                        type="primary"
                        htmlType="button"
                        className="loginButton"
                        onClick={() => setEditMode(true)}
                      >
                        Edit Invoice
                      </Button>
                    </Col>
                  </Row>
                )} */}
                </Row>
              </Col>
            </Row>
          </Form>


        </>)}



        <br />
        <br />
      </div>

      <br />
      <br />
    </Layout>
  );
}
export default InvoiceDetails;

const RecordViewer = ({ pdfLinks }) => {
  return (
    <List
      bordered
      dataSource={pdfLinks}
      renderItem={item => (
        <List.Item>
          <a href={UPLOAD_URL + item} target="_blank" rel="noopener noreferrer">
            {item}
          </a>
        </List.Item>
      )}
    />
  );
};

const createFileList = (arr) => {
  try {
    return arr?.map(item => ({
      uid: item,
      name: item,
      status: 'done',
      url: UPLOAD_URL + item,
      percent: 100,
    }))
  } catch (error) {
    console.log(error)
  }
}